//Contact Page Element
.contactElement {
  img {
    float: left;
    margin-bottom: 10px;
    margin-right: 20px;
    max-width: 50px;
    max-height: 100px;
    // Apply these styles only if img is present
    + div {
      max-width: calc(100% - 70px); // Assumes 50px img
      h3 {
        margin-top: -3px; // Align h3 with image
      }
    }
  }
  h3 {
    color: $link-color;
    font-size: 1.2em;
    font-weight: 600;
    text-transform: none;
  }
  p {
    color: $bodyCopyColorTwo;
    font-size: .75em;
    font-weight: inherit;
    line-height: 1.2em;
    margin-top: 4px;
    margin-bottom: 5px;
    text-align: left;
    &:last-of-type {
      margin-bottom: 9px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .title {
    border: none;
    padding: 0;
  }
}

.contact-element-first-name,
.contact-element-last-name {
  display: inline-block;
  font-size: 24px;
}
