// 5. Site Footer
.snFooterContainer {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  background-color: $link-color;
}

#siteFooter {
  @extend %clearfix;
  display: block;
  margin: auto;
  padding: 10px 20px;
  position: relative;
  vertical-align: bottom;
  width: 100%;
  max-width: $maxWidth;
  font-size: .8em;
  clear: both;

  .footer-content {
    padding: 15px 0;

    @media only screen and (max-width: 1024px){
      padding: 0;

      br { display: none; }

    }

    .column {
      padding-left: 30px;
      padding-right: 30px;
    }

    a, p {
      color: #ffffff;
      font-family: $fontOne;
      font-weight: 100;
      letter-spacing: .5px;
      font-size: 20px;
      transition: color $transition-standard;
      margin-bottom: 0;
    }

    p { float: left; }

    a:hover {
      text-decoration: none;
      color: $accent-color;
    }

    .flex {

      .text {
        display: flex;
        flex-wrap: wrap;

        p {
          flex-grow: 1;
          padding: 0 10px;

          @media only screen and (max-width: 1024px){ flex-grow: 0; }

        }

      }

    }

  }

  > ul {
    padding: 0 30px;
    @include displayCenter($maxWidth);

    li {
      display: inline-block;
      padding: 0 .2em;
      min-height: 20px;
      color: #ffffff;

      a {
        display: inline-block;
        color: $accent-color;
      }

      &:hover {
        display: inline-block;
      }
    }
  }
}

#siteFooter li#poweredByNGIN {
  display: none;
  
  a {
    display: block;
    width: 120px;
    height: 20px;
    position: relative;

    &:after {
      content: url($asset-path-for+'/logo_images/logo.svg');
      display: block;
      position: absolute;
      top: 4px;
      left: 0;
      width: 100%;
    }

    img {
      display: none;
    }
  }
}

@media only screen and (max-width: 1024px) {
  #siteFooter {
    li {
      min-height: 0;
    }
  }

  #siteFooter li#poweredByNGIN {
    padding: 10px 0;
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  #siteFooter {
    li {
      min-height: 0;
    }
  }

  #siteFooter li#poweredByNGIN {
    padding: 10px 0;
  }
}
