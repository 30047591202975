.user_mode {

  .sponsor-slider {
    padding: 0 35px;
    border-top: solid 1px $gray;
    border-bottom: solid 1px $gray;

    .slick-next,
    .slick-prev {
      height: 100%;
      width: 35px;
      transition: all $transition-standard;

      &:before {
        color: #a6a6a6;
        font-family: FontAwesome;
      }

      &:hover {
        background-color: #eeeeee;
      }

    }

    .slick-next {
      right: -35px;
      border-left: solid 1px $gray;

      &:before { content: '\f105'; }

    }

    .slick-prev {
      left: -35px;
      border-right: solid 1px $gray;

      &:before { content: '\f104'; }

    }

    .pageEl {
      height: 100%;
    }

    .slick-track { display: flex; }

    .slick-initialized .slick-slide {
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 0;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 1px;
        background-color: $gray;
        top: 0;
        right: 0;
      }

      .pageElement {
        max-width: 75%;
        display: flex;
        align-items: center;
        justify-content: center;

        a,
        img {
          display: block;
          max-height: 100%;
          max-width: 100%;
        }

      }

    }

  }

}

.edit_mode {

  .sponsor-slider .heroPhotoElement {
    text-align: center;

    img { max-width: 150px; }

  }

}
