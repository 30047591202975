$site-logo-size: 100px;
$site-logo-size-fixed: 50px;

#sn-site-logo {
  height: $site-logo-size;
  z-index: 1000;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transition: all $transition-linear;
  max-width: $maxWidth;
  margin: auto;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background: $accent-color; /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(left, transparent , $accent-color , transparent); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(right, transparent , $accent-color, transparent); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(right, transparent , $accent-color, transparent); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to right, transparent , $accent-color , transparent); /* Standard syntax */
  }

  .nav-fixed & {
    height: $site-logo-size-fixed;
  }

  .sn-site-logo-wrap {
    display: block;
    height: 100%;
    width: 100%;
  }

  .sn-site-logo-background {
    height: 100%;
    display: block;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 75%;
    -webkit-background-size: auto 75%;
  }

  html.has-mobile-nav & {
    height: 44px;
    margin: 0 50px;

    .sn-site-logo-background { background-size: auto 60%; }

  }

}
