// 4. Site Header

// .home #displayBodyHeader { display: none; }
.site-banner-wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: flex-end;
  background-size: cover;
  margin: 0 auto;
  max-width: 100%;
  position: relative;
  width: 100%;

  .site-banner-text-wrapper {
    color: #fff;
    font-size: 36px;
    line-height: 1;
    padding: 0;
    text-align: left;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;

    h2, h3 {
      font-size: 1em;
      line-height: 1.25;
      text-transform: none;
    }

    h1 {
      @include special-header;
      display: inline-block;
      background: #ffffff;
      padding: 10px 20px 0;
      text-transform: uppercase;
      font-size: 32px;

      &:before {
        content: '/ /';
        color: $link-color;
        margin-right: 7px;
      }

      @media only screen and (max-width: 768px){
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background-color: #ffffff;
      }

    }
  }
}

@media (max-width: 699px) {
  .site-banner-wrapper .site-banner-text-wrapper {
    font-size: 4.5vw;

    h1 { font-weight: 700; }
    h2, h3 { display: none; }
  }
}

@media (min-width: 700px) and (max-width: 1000px) {
  .site-banner-wrapper .site-banner-text-wrapper { font-size: 3.6vw; }
}

@media (max-width: 999px){

  .site-banner-wrapper {
    min-height: 50px !important;
    border-bottom: solid 1px $gray;
  }

}
