#siteContainer {
  &, #siteContainer.container-fluid {
    padding: 15px 0;
    width: 100%;
    max-width: $maxWidth;
    min-height: 100%;
    min-height: 100vh;
    min-height: calc(100vh - #{$nav-placeholder-height} - #{$site-footer-height} - #{$account-nav-height-desktop});
    margin: 0 auto;
    background: #fff;


    .has-body-header & {
      padding: 0;

      @media only screen and (min-width: 768px){
        margin-top: 100px;
      }

    }

    .user_mode.home:not(.newsPage) &,
    #testing &,
    .splash-active & {
      max-width: 100%;
      padding-top: 0;
      padding-bottom: 0;

      #panelTwo { padding: 0; }

      .inner-layout {
        margin: auto;
        max-width: $maxWidth;
      }

      .full-width .inner-layout {
        max-width: 100%;

        .column { padding: 0; }

        .pageElement { margin: 0; }

      }

    }

  }

  @media only screen and (max-width: 1024px) { max-width:1024px; }

  #displayBodyHeader:not(.displayBodyHeader-image) {
    @include special-header;
    padding: 10px 30px 0;
    font-size: 2.25em;
    font-weight: 600;
    text-transform: initial;
    background-color: #eeeeee;
    text-transform: uppercase;
    color: $accent-color;
    border-bottom: solid 1px #dddddd;

    span:before {
      color: $link-color;
      display: none;
    }

    @media only screen and (min-width: 768px){
      margin-top: -70px;
      float: left;
      padding: 15px 30px;
      border-bottom: none;
      background-color: #ffffff;

      span:before {
        display: inline-block;
        color: $link-color;
      }

    }

  }

  #yieldContent.row { margin: auto; }

  #panelOne { padding: 0; }

  .yieldPageContent { padding:0; }
}
