// Typekit
.wf-loading { display: none; }

.wf-active { display: block; }

// home page feature
.user_mode .feature {
  position: relative;

  .feature-text {
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 0 10%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0,0,0,.25); /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(transparent, rgba(0,0,0,.6)); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(transparent, rgba(0,0,0,.6)); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(transparent, rgba(0,0,0,.6)); /* For Firefox 3.6 to 15 */
    background: linear-gradient(transparent, rgba(0,0,0,.6)); /* Standard syntax */

    h3 {
      color: #ffffff;
      font-weight: 600;
      font-style: italic;
      font-size: 9vw;
      line-height: 9vw;
      text-shadow: 2px 3px 3px rgba(0,0,0,.7);

      span:before { display: none; }

    }

  }

}

.feature video { width: 100%; }

video { display: block; }

// container bg images
// Container Background Image
.edit_mode .container-bg { display: block; }
.user_mode .container-bg { display: none; }

.has-container-bg {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  &.repeat-bg { background-size: auto; }

}

// color containers
.user_mode {

  .black-bg,
  .yellow-bg {

    .pageElement {
      padding: 20px;
    }

  }

  .black-bg {
    background-color: $link-color;

    p { color: #ffffff; }

    .textBlockElement .text {

      h3 {
        color: #ffffff;
        font-weight: 100;
      }

    }

    .linkElement h4 a:hover { color: $link-color; }

    &.special-link .textBlockElement {

      h3 {
        color: $accent-color;

        span:before { color: #ffffff; }

      }

      a {
        border: solid 1px #ffffff;
        color: #ffffff;

        &:hover {
          background-color: #ffffff;
          color: $link-color;
        }

      }

    }

  }

  .yellow-bg {
    background-color: $accent-color;

    &.special-link .textBlockElement {

      h3 {
        color: $link-color;

        span:before { color: #ffffff; }

      }

      a {
        border: solid 1px $link-color;
        color: $link-color;

        &:hover {
          background-color: $link-color;
          color: $accent-color;
        }

      }

    }

  }

}

// custom headers
.textBlockElement {

  h3 { @include special-header(); }

  .special-link & {

    a { @include special-link(); }

  }

}

// extra padding for home page containers
.user_mode {

  .extra-padding { padding: 25px 0; }

  .top-padding { padding-top: 25px; }

  @media only screen and (min-width: 768px){
    .extra-padding { padding: 50px 0; }
  }

}

// widths
.user_mode {

  .half-width,
  .half-width .pageEl {
    float: left;
    width: 50%;

    @media only screen and (max-width: 350px){ width: 100%; }

  }

  @media only screen and (min-width: 768px){

    .third-width,
    .third-width .pageEl {
      float: left;
      width: 33.3333333%;
    }

    .third-width .pageEl,
    .third-width.pageEl {
      width: calc(33.3333333% - 15px);
    }

  }

  .third-width .pageEl,
  .third-width.pageEl { margin: 0 7.5px 15px; }

}

// flex align
.flex-align .inner-layout {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

// splash
.splash-active {

  .theme-main-nav,
  #topNavPlaceholder,
  .footer-content { display: none !important; }

  .snFooterContainer { background-color: transparent; }

  #siteFooter > ul {
    text-align: center;

    li { color: $link-color; }

  }

  #topNav {
    background-color: transparent;

    &.nav-fixed { position: absolute; }

  }

  #sn-site-logo{
    height: 120px;
    padding: 5px;

    &:before { display: none; }

  }

  #siteContainer {
    max-width: 100%;
    background: transparent;
  }

  .splash-overlay { padding-top: $nav-placeholder-height - 60; }

  .has-container-bg {
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

}

html:not(.collapsed-mobile-nav):not(.has-sub-nav){

  .site-background,
  &.nav-fixed .splash-active .site-background { margin-top: 0; }

}

@media only screen and (max-width: 1024px){

  html.has-mobile-nav .splash-active #sn-site-logo {
    height: 120px;
    padding: 5px;
  }

  .has-mobile-nav body.splash-active { padding-top: 0; }

  .splash-active .theme-slide-nav-toggle { display: none !important; }

}

@media only screen and (max-width: 550px){
  html.has-mobile-nav .splash-active #sn-site-logo .sn-site-logo-background { background-size: 100% auto; }
}

// splash video
#splash-video {
  position: relative;

  &:hover { cursor: pointer; }

  #video-player {
    margin: auto;
    display: inherit;
    width: 100%;
  }

  &.paused {

    &:after {
      content: '\f04b';
      display: inline-block;
      font-family: FontAwesome;
      color: $accent-color;
      padding: 0 14px 0 22px;
      border-radius: 100px;
      background-color: $link-color;
      position: absolute;
      top: calc(50% - 37px);
      left: calc(50% - 37px);
      font-size: 50px;
      transition: color $transition-standard;
    }

    &:hover:after { color: #ffffff; }

  }

}

// email link
.email-link {

  .text a {
    @include special-link;
    font-family: $fontTwo;
    border: none;

    &:before {
      content: '\f003';
      margin-right: 5px;
      font-family: FontAwesome;
    }

  }

}

// first th yellow on table
.highlight-first {

  table tr th:first-of-type { color: $accent-color; }

}

// special caption
.special-caption {

  .heroPhotoElement .cutline {
    background-color: $link-color;
    color: #ffffff;
    font-style: normal;
    font-size: 16px;
    border-bottom: solid 7px $accent-color;
  }

}
