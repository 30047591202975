/* Call to Action Element */
// 1. Default Styles
// 2. Variations
// 3. Edge Case Fixes
// 4. Fix for tall text in short container
// 5. Adjustments for Small Columns & Mobile Views
// 6. Accomidation for touch screens

// 1. Default Styles
.sn-call-to-action,
.sn-call-to-action * {
  box-sizing: border-box;
}
.sn-call-to-action {
  cursor: pointer;
  position: relative;
  overflow: hidden;
 &:before {
    content:"";
    position:absolute;
    top:0;
    left:0;
    display:none;
    width:100%;
    height:5px;
    background:$accent-color;
    border-bottom:2px solid #fff;
    z-index:10;
  }
  &:after {
    content:"";
    position:absolute;
    bottom:0;
    left:0;
    display:none;
    width:100%;
    height:3px;
    border-top:2px solid #fff;
    z-index:10;
    @include line-pattern(#aaa,#fff);
  }
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
  }
  .sn-call-to-action-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 20px;
    z-index: 1;
    text-align: center;
    background-color: rgba(0,0,0,0.6);
    transition: background-color $transition-standard;

    &:hover { background-color: rgba(0,0,0,0.75); }

    .yellow-cta & {
      background-color: rgba(255, 183, 26, .5);

      &:hover { background-color: rgba(255, 183, 26, .65);  }

    }

  }
  .sn-call-to-action-overlay-inner {
    display: table;
    width: 100%;
    height: 100%;
  }
  .sn-call-to-action-overlay-text {
    display: table-cell;
    font-size: rem(10);
    height: 100%;
    line-height: 1;
    vertical-align: middle;
  }
  .sn-call-to-action-title {
    @include special-header;
    color: $accent-color;
    display: inline-block;
    text-shadow: 2px 2px 5px rgba(0,0,0,.9);

    &:before {
      content: '/ /';
      color: #ffffff;
      margin-right: 7px;
    }

    .yellow-cta & {
      color: #ffffff;

      &:before { color: $link-color; }

    }

  }
  .sn-call-to-action-subtitle {
    color: #fff;
    font-family:$fontOne;
    font-size: rem(18);
    font-weight: 400;
    line-height: 130%;
    margin-bottom:0;
    letter-spacing: .5px;
    a,
    span {
      margin: 0 .25em;
      color: #fff;
      display: inline-block;
    }
  }
}
.sn-call-to-action .sn-call-to-action-subtitle:first-of-type > * {
  padding-top: 5px;
}

// 2. Variations

/* Slide Up */
.sn-call-to-action.sn-variation-slide-up {
  .sn-call-to-action-overlay {
    background: $link-color_opacity;
    top: auto;
    height: 0;
    transition: height 0.5s ease;
  }
  .sn-call-to-action-title {
    margin-bottom: 20px;
  }
  .sn-call-to-action-subtitle {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  &:hover {
    .sn-call-to-action-overlay {
      height: 100%;
    }
    .sn-call-to-action-subtitle {
      opacity: 1;
    }
  }
}

/* Zoom */
.sn-call-to-action.sn-variation-zoom {
  img {
    transform: scale(1);
    transition: transform 0.5s ease;
  }
  &:hover {
    img {
      transform: scale(1.2);
    }
  }
}

/* Text Zoom */
.sn-call-to-action.sn-variation-text-zoom {
  .sn-call-to-action-overlay-text {
    transform: scale(1);
    transition: transform 0.5s ease;
  }
  &:hover {
    .sn-call-to-action-overlay-text {
      transform: scale(1.1);
    }
  }
}

/* Border */
.sn-call-to-action.sn-variation-border {
  .sn-call-to-action-overlay {
    box-shadow: inset 0 0 0 0 transparent, inset 0 0 0 0 transparent;
    transition: box-shadow 0.5s ease-in;
  }
  &:hover {
    .sn-call-to-action-overlay {
      box-shadow: inset 0 7px 0 10px $link-color_opacity, inset 0 -5px 0 10px $link-color_opacity;
    }
  }
}

// 3. Edge Case Fixes

/* mirrored CTA preview img alignment fixes */
.pe25 .elementPreview img,
.pe33 .elementPreview img {
  width: 100%;
  margin: 0px;
}

// 4. Fix for tall text in short container
.sn-call-to-action.sn-variation-tall-text {
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    max-width: initial;
    min-width: 100%;
    transform: translate(-50%, -50%);
  }
}
.sn-call-to-action.sn-variation-zoom.sn-variation-tall-text {
  &:hover {
    img {
      transform: scale(1.2) translate(-40%, -40%);
    }
  }
}

// 5. Adjustments for Small Columns & Mobile Views

/* paragraph spacing */
.pe25 .sn-call-to-action-overlay-text p {
  margin-bottom: .15em;
}

@media only screen and (min-width: 768px) and (max-width: 1000px) {
  .col-md-4 .sn-call-to-action, .col-md-3 .sn-call-to-action{
    .sn-call-to-action-title{
      font-size: rem(20);
    }
    .sn-call-to-action-subtitle{
      font-size: rem(14);
    }
  }
}
@media only screen and (max-width: 350px) {
  .sn-call-to-action{
    .sn-call-to-action-title{
      font-size: rem(20);
    }
    .sn-call-to-action-subtitle{
      font-size: rem(14);
    }
  }
}

// 6. Accomodation for touch screens
.touch-screen {
  .sn-call-to-action.sn-variation-slide-up,
  .sn-call-to-action.sn-variation-slide-up:focus,
  .sn-call-to-action.sn-variation-slide-up:hover{
    .sn-call-to-action-overlay {
      height: 100%;
      transition: none;
    }
    .sn-call-to-action-subtitle {
      opacity: 1;
      transition: none;
    }
  }
  .sn-call-to-action.sn-variation-border,
  .sn-call-to-action.sn-variation-border:focus,
  .sn-call-to-action.sn-variation-border:hover{
    .sn-call-to-action-overlay{
      transition: none;
      box-shadow: inset 0 7px 0 10px $link-color_opacity, inset 0 -5px 0 10px $link-color_opacity;
    }
  }
  .sn-call-to-action.sn-variation-text-zoom,
  .sn-call-to-action.sn-variation-text-zoom:focus,
  .sn-call-to-action.sn-variation-text-zoom:hover{
    .sn-call-to-action-overlay-text{
      transition: none;
      transform: scale(1);
    }
  }
}


.custom-cta{
  .sn-call-to-action{
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: center;
    height: 100% !important;
    &:before{
      content: "";
      display: block;
      height: auto;
      border: 0;
      position: relative;
      width: auto;
    }
    &.sn-variation-tall-text{
      height: auto !important;
    }
    &.sn-variation-tall-text img{
      // opacity: 0;
      // display: none;
      position: relative;
      top: auto;
      left: auto;
      width: 100%;
      max-width: 100%;
      transform: none;
      height: auto !important;
    }
    .sn-call-to-action-overlay{
      // position: unset;
      background-color: transparent !important;
      &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: -1;
        transition: background-color $transition-linear;
      }
      &:hover:before{
        background-color: rgba(0, 0, 0, 0.75);
      }
    }
  }
  &.yellow-cta .sn-call-to-action .sn-call-to-action-overlay:before{ background-color: rgba(255, 183, 26, 0.5); }
  &.yellow-cta .sn-call-to-action .sn-call-to-action-overlay:hover:before{ background-color: rgba(255, 183, 26, 0.65); }
  &.ratio-1-1,
  &.ratio-2-1{
    .sn-call-to-action{
      img{
        opacity: 0;
        display: none;
      }
      .sn-call-to-action-overlay{
        position: unset;
      }
    }
  }
  &.ratio-1-1 .sn-call-to-action:before{ padding-bottom: 83.29885932%; }
  &.ratio-2-1 .sn-call-to-action:before{ padding-bottom: 41.64942966%; }
}

// 657.5x547.69 = 83.29885932
// 328.75x273.84 = 0.832973384
// 657.5x274.17 = 41.69885932
